import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import { GenericList } from 'components/Common/GenericList'
// import TableActions from 'components/TableUI/TableActions'

import { useNavigate, useParams } from 'react-router-dom'
import MerchantSubmenu from '../MerchantSubmenu'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import MerchantAlias from '../MerchantAlias'
import PageTitle from 'components/Misc/PageTitle'
import MerchantMenu from '../MerchantMenu'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'


export default function MerchantSubmethodsList() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { userId } = useParams()

    return <Card>
        <CardContent>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <PageTitle title={t('Submethods merchant fees')} />

                <Hidden xlUp={true}>
                    {userId ? <MerchantMenu id={userId} /> : ''}
                </Hidden>
            </Stack>
            <MerchantAlias />
            <Grid container spacing={2} mt={2} mb={2} style={{ width: "100%" }}>
                <Grid item xs={12} sx={{
                    textAlign: 'right'
                }}>
                    <ButtonWrapper
                        variant="text"
                        size="small"
                        onClick={() => navigate('/merchants')}
                        startIcon={<ArrowBackIcon />}>
                        {t('Go back')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <Stack direction="row">
                <MerchantSubmenu current='submethods-and-fees' id={userId} />
                <GenericList
                    style={{ width: "100%" }}
                    url={`/internal/merchants/${userId}/submethods/`}
                    // editableFields={new Set(["name"])}
                    hiddenFields={new Set(["id", "status_"])}
                    translatedChoiceFields={new Set(["status"])}
                />
            </Stack>
        </CardContent>
    </Card>
}
