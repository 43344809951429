import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import BusinessIcon from '@mui/icons-material/Business'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { Hidden, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material'
import {
    ACCOUNT_CONFIG__UPDATE, ACCOUNT_CONFIG__VIEW, BANK_ACCOUNTS__CREATE, BANK_ACCOUNTS__DELETE, BANK_ACCOUNTS__UPDATE,
    BANK_ACCOUNTS__VIEW, COUNTRY_SPREADS__CREATE, COUNTRY_SPREADS__DELETE, COUNTRY_SPREADS__UPDATE, COUNTRY_SPREADS__VIEW,
    MERCHANTS__UPDATE, MERCHANT_CONTACTS__CREATE, MERCHANT_CONTACTS__DELETE, MERCHANT_CONTACTS__UPDATE, MERCHANT_CONTACTS__VIEW,
    MERCHANT_PAYMENT_METHODS__UPDATE, MERCHANT_PAYMENT_METHODS__VIEW, MISCELLANEOUS__CREATE, MISCELLANEOUS__DELETE, MISCELLANEOUS__UPDATE,
    MISCELLANEOUS__VIEW, ADDRESSES__VIEW, ADDRESSES__CREATE, ADDRESSES__UPDATE, ADDRESSES__DELETE
} from '../../../enums/Caps'
import PreviewIcon from '@mui/icons-material/Preview'
import useHasPermissions from '../../../hooks/useHasPermissions'
import SubmerchantIcon from './Submerchants/Icon'

export default function MerchantSubmenu({
    id,
    current = null
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const [logo, setLogo] = useState()

    const handleNavigate = (view) => {
        navigate(`/merchants/${id}/${view}`)
    }

    useEffect(() => {
        if (localStorage.getItem('merchant')) {
            const merchant = JSON.parse(localStorage.getItem('merchant'))

            if (
                merchant.account_config &&
                merchant.account_config.logo_file
            ) {
                const _logo = /cdn.paygol.com/.test(merchant.account_config.logo_file) ? merchant.account_config.logo_file : `https://cdn.paygol.com/merchant/logos/${merchant.account_config.logo_file}`

                setLogo(_logo)
            }
        }
    }, [])

    return (
        <Hidden xlDown={true}>
            {logo && (<Stack direction="row" justifyContent="center"><img
                alt="Paygol"
                src={logo}
                style={{
                    maxWidth: '90%',
                    textAlign: 'center',
                    margin: '1.5rem auto'
                }}
            /></Stack>)}

            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton
                    selected={current === 'edit'}
                    onClick={() => current !== 'edit' ? handleNavigate('edit') : ''}
                >
                    <ListItemIcon>
                        {hasPermissions(MERCHANTS__UPDATE) ? <EditIcon /> : <PreviewIcon />}
                    </ListItemIcon>
                    <ListItemText primary={hasPermissions(MERCHANTS__UPDATE) ? t('Edit') : t('View')} />
                </ListItemButton>

                {hasPermissions([ACCOUNT_CONFIG__VIEW, ACCOUNT_CONFIG__UPDATE]) ? (<ListItemButton
                    selected={current === 'settings'}
                    onClick={() => current !== 'settings' ? handleNavigate('config') : ''}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Settings')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                ]) ? (<ListItemButton
                    selected={current === 'methods-and-fees'}
                    onClick={() => current !== 'methods-and-fees' ? handleNavigate('methods') : ''}
                >
                    <ListItemIcon>
                        <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Methods & fees')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                ]) ? (<ListItemButton
                    selected={current === 'submethods-and-fees'}
                    onClick={() => current !== 'submethods-and-fees' ? handleNavigate('submethods') : ''}
                >
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Submethods & fees')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    BANK_ACCOUNTS__VIEW, BANK_ACCOUNTS__CREATE, BANK_ACCOUNTS__UPDATE, BANK_ACCOUNTS__DELETE
                ]) ? (<ListItemButton
                    selected={current === 'bank-accounts'}
                    onClick={() => current !== 'bank-accounts' ? handleNavigate('bank-accounts') : ''}
                >
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Bank accounts')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    COUNTRY_SPREADS__VIEW, COUNTRY_SPREADS__CREATE, COUNTRY_SPREADS__UPDATE, COUNTRY_SPREADS__DELETE
                ]) ? (<ListItemButton
                    selected={current === 'spreads'}
                    onClick={() => current !== 'spreads' ? handleNavigate('spreads') : ''}
                >
                    <ListItemIcon>
                        <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Spreads')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    MISCELLANEOUS__VIEW, MISCELLANEOUS__CREATE, MISCELLANEOUS__UPDATE, MISCELLANEOUS__DELETE
                ]) ? (<ListItemButton
                    selected={current === 'miscellaneous'}
                    onClick={() => current !== 'miscellaneous' ? handleNavigate('miscellaneous') : ''}
                >
                    <ListItemIcon>
                        <MiscellaneousServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Miscellaneous')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    MERCHANT_CONTACTS__VIEW, MERCHANT_CONTACTS__CREATE, MERCHANT_CONTACTS__UPDATE, MERCHANT_CONTACTS__DELETE
                ]) ? (<ListItemButton
                    selected={current === 'contacts'}
                    onClick={() => current !== 'contacts' ? handleNavigate('contacts') : ''}
                >
                    <ListItemIcon>
                        <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Contacts')} />
                </ListItemButton>) : ''}

                {hasPermissions([
                    ADDRESSES__VIEW, ADDRESSES__CREATE, ADDRESSES__UPDATE, ADDRESSES__DELETE
                ]) ? (<ListItemButton
                    selected={current === 'addresses'}
                    onClick={() => current !== 'addresses' ? handleNavigate('addresses') : ''}
                >
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Addresses')} />
                </ListItemButton>) : ''}
                <ListItemButton
                    selected={current === 'submerchants'}
                    onClick={() => current !== 'submerchants' ? handleNavigate('submerchants') : ''}
                >
                    <ListItemIcon>
                        <SubmerchantIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Submerchants')} />
                </ListItemButton>
            </List>
        </Hidden>
    )
}
