import { useState } from 'react';

import { useTranslation } from 'react-i18next'

import { TextField, MenuItem } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Stack, Button } from '@mui/material';

import { getTextWidth } from 'utils/domUtils'


function textFieldWidth(nChars) {
    return `calc(calc(${getTextWidth("Z".repeat(nChars), "font-family: sans-serif;")}px * 1.05) + 2rem)`
}


function SelectField({ choices, ...otherProps }) {
    return <TextField {...otherProps} select={true} style={{ minWidth: `calc(${textFieldWidth(otherProps.label.length)} + 2rem)` }} variant='outlined'>
        {Object.keys(choices).map((item, pos) => {
            return <MenuItem key={pos} value={item}>
                {choices[item]}
            </MenuItem>
        })}
    </TextField>
}


function BooleanField({ ...otherProps }) {
    const { t } = useTranslation()

    return <SelectField choices={{ true: t("Yes"), false: t("No") }} {...{ ...otherProps }} />
}


/**
 * A feld that allows the user to load a file from the local filesystem,
 * and set the value of the field to the contents of the file converted to base64.
 * 
 * An upload button is displayed, and when clicked, the user is prompted to select a file.
 * When a file is selected, the value of the field is set to the base64 encoded contents of the file,
 * and the filename is displayed as a little text below the button.
 */
function Base64FIleField({ ...otherProps }) {
    const { t } = useTranslation()

    const [file, setFile] = useState(null)

    const uploadFile = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.onchange = (e) => {
            const file = e.target.files[0]
            const reader = new FileReader()
            reader.onloadend = () => {
                setFile(file)
                // Extract the data part of the url, which is the base64 encoded file contents
                const base64Data = reader.result.split(',')[1]
                otherProps.onChange({ target: { value: base64Data } })
            }
            reader.readAsDataURL(file)
        }
        input.click()
    }

    const error = otherProps.helperText

    return <Stack spacing={0.1}>
        <div style={{ fontSize: "0.6rem" }}>{otherProps.label}</div>
        <Button
            variant={file ? "contained" : "outlined"}
            color="primary"
            onClick={uploadFile}
            startIcon={<UploadFileIcon />}
        >
            {t(file ? "Change file" : "Select file")}
        </Button>
        {file && <div>{file.name}</div>}
        {error && <div style={{ fontSize: "0.6rem", color: "red" }}>{error}</div>}

    </Stack>
}

function ValueEntryField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const label = t(fieldMetadata.label)

    switch (fieldMetadata.type) {
        case "choice":
            return <SelectField
                label={label}
                choices={fieldMetadata.choices}
                {...otherProps}
            />
        case "boolean":
            return <BooleanField
                label={label}
                {...otherProps}
            />
        case "base64_file":
            return <Base64FIleField label={label} {...otherProps} />
        case "string":
            // FIXME
            // @R.Pani
            // Cuando se pasa el width a un elemento muy grande, se produce quiebre en el responsive
            // Se quita para correjir overflow producido en formulario configuracion del merchant
            // return <TextField label={label} {...otherProps} style={{ width: textFieldWidth(effectiveLength) }} />
            return <TextField label={label} {...otherProps} />
        case "datetime":
            return <TextField InputLabelProps={{ shrink: true }} label={label} {...otherProps} type="datetime-local" />
        default:
            return <TextField label={label} {...otherProps} />
    }
}


/**
 * Make a field nullable by showing an add button when the field is null
 * to set a non-null value. When the field is not null, show the field
 * and a delete button to set the field to null.
 */
export function AddButtonNullableField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const [showField, setShowField] = useState(!((otherProps.value === null || otherProps.value === undefined)))

    function hideField() {
        setShowField(false)
        otherProps.onChange({ target: { value: null } })
    }

    function doShowField() {
        setShowField(true)
        otherProps.onChange({ target: { value: "" } })
    }

    if (otherProps.value === null) {
        otherProps.value = '';
    }

    return (
        <Stack direction="row">
            {showField ?
                <>
                    <IconButton onClick={hideField}>
                        <DeleteIcon />
                    </IconButton>
                    <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
                </>
                :
                <>
                    <IconButton onClick={doShowField}>
                        <AddIcon />
                    </IconButton>
                    <p style={{ opacity: 0.5 }}>{t(fieldMetadata.label)}</p>
                </>
            }
        </Stack>
    )
}


/**
 * Make a field nullable by showing a boolean field to set the field to null.
 * The boolean field can have a custom label
 * When the field is not null, show the field and make it required.
 */
export function BooleanNullableField({ fieldMetadata, switchLabel = "", ...otherProps }) {

    const [showField, setShowField] = useState(!((otherProps.value === null || otherProps.value === undefined)))

    function hideField() {
        setShowField(false)
        otherProps.onChange({ target: { value: null } })
    }

    function doShowField() {
        setShowField(true)
        otherProps.onChange({ target: { value: "" } })
    }

    if (otherProps.value === null) {
        otherProps.value = '';
    }

    return (
        <Stack direction="row" spacing="0.5rem">
            <BooleanField
                label={switchLabel}
                value={showField}
                onChange={showField ? hideField : doShowField}
                size={otherProps.size}
            />
            {showField &&
                <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
            }
        </Stack>
    )
}


/**
 * Wrap ValueEntryField to handle nullable fields allowing to set them to null
 * by clicking on a button that hides the field, sets the value to null
 * and shows a button to show it again.
 * Only wrap when the field is nullable, this is checked on fieldMetadata.allow_null.
 */
export default function EditField({ fieldMetadata, ...otherProps }) {
    return (
        fieldMetadata.allow_null ?
            <AddButtonNullableField fieldMetadata={fieldMetadata} {...otherProps} />
            :
            <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} />
    )

}
