import { GenericCrud } from 'components/Common/GenericList'
// import TableActions from 'components/TableUI/TableActions'

import { Stack, Grid, Card, CardContent, Hidden } from '@mui/material'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import MerchantSubmenu from '../MerchantSubmenu'
import PageTitle from 'components/Misc/PageTitle'
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'


export default function MerchantSubmerchantsList() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { userId } = useParams()

    return <>
        <Card>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <PageTitle title={t('Submerchants')} />

                    <Hidden xlUp={true}>
                        {userId ? <MerchantMenu id={userId} /> : ''}
                    </Hidden>
                </Stack>

                <MerchantAlias />

                <Grid container spacing={2} mt={2} mb={2}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} sx={{
                        textAlign: 'right'
                    }}>
                        <ButtonWrapper
                            variant="text"
                            size="small"
                            onClick={() => navigate('/merchants')}
                            className="base__btn-action"
                            startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} xl={2}>
                        <MerchantSubmenu current='submerchants' id={userId} />
                    </Grid>
                    <Grid item xs={12} xl={10}>
                        <GenericCrud
                            url={`/internal/merchants/${userId}/submerchants/`}
                            // title={"Submerchants"}
                            // editableFields={new Set(["name"])}
                            hiddenFields={new Set(["id"])}
                        />
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    </>
}
