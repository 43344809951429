import { toast } from 'react-toastify'

export function createDowndloadFunction(url, setFetchingData, isMounted, axiosPrivate, t, params) {
    return () => {
        setFetchingData(true)

        axiosPrivate.get(url, { params: params, responseType: 'blob' })
            .then(response => {
                if (!isMounted()) {
                    return
                }
                console.log(response)
                let anchor = document.createElement('a')

                anchor.href = window.URL.createObjectURL(response.data)

                let headerLine = response.headers['content-disposition']
                let startFileNameIndex = headerLine.indexOf('"') + 1
                let endFileNameIndex = headerLine.lastIndexOf('"')
                let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)

                anchor.download = filename

                anchor.click()

                anchor.remove()
                toast.success(t('Exported successfully, wait a few seconds for the download to start'))
            })
            .catch((error) => {
                if (!isMounted()) {
                    return
                }
                if (error.response.status !== 500 && error?.response?.data?.text) {
                    error.response.data.text().then((data) => {
                        if (!isMounted()) {
                            return
                        }
                        const responseObjectFromDataJSONBlob = JSON.parse(data)
                        const errorData = responseObjectFromDataJSONBlob.error
                        if (!errorData?.message || !errorData?.errors?.detail) {
                            toast.error(t('Something went wrong'))
                            return
                        }
                        const message = `${errorData.message}, ${errorData.errors.detail}`
                        toast.error(t(message))
                    })
                }
                else {
                    toast.error(t('Something went wrong'))
                }
            })
            .finally(() => isMounted() && setFetchingData(false))
    }
}