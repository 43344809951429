import React, { useState, useEffect } from 'react'
import { Card, CardContent, Grid, Hidden } from '@mui/material'
import PageTitle from '../../../Misc/PageTitle'
import MethodsForm from './MethodsForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'
import MerchantMenu from '../MerchantMenu'
import AppFilters from '../../../AppFilters'
import Filters from './Filters'
import BackdropWrapper from '../../../BackdropWrapper/BackdropWrapper';
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';


export default function MerchantMethods() {
  const { t } = useTranslation()

  const isMounted = useIsMounted()

  const api = useAxiosPrivate()

  const { userId } = useParams()

  const [loading, setLoading] = useState(true)

  const [methods, setMethods] = useState([])

  const [filtered, setFiltered] = useState([])

  const handleOnSearch = values => {
    setLoading(true)

    values = {
      ...values,
      status: String(values.status).replace('all', ''),
      country: String(values.country).toUpperCase()
    }

    const regex = new RegExp(values.country)
    const regex_name = new RegExp(values.name, 'i')

    const _filtered = methods.filter(d => (
      d.default_method && regex.test(d.default_method.countries) && regex_name.test(d.default_method.name) && (values.status ? parseInt(d.status) === parseInt(values.status) : true)
    ))

    setFiltered([])
    setFiltered(_filtered)

    setTimeout(() => setLoading(false), 500)
  }

  const handleUpdateMethods = (method) => {
    setMethods(
      methods.map((list_method) => list_method.api_code === method.api_code ? method : list_method)
    )
  }

  useEffect(() => {
    const init = () => {
      api.get(`/internal/merchants/${userId}/payment-methods/`)
        .then(({ data: { data } }) => {
          if (isMounted()) {
            setMethods(data)
            setFiltered(data)
          }
        })
        .catch(() => isMounted() && toast.error(t('Something went wrong')))
        .finally(() => isMounted() && setLoading(false))
    }

    init()
  }, [t, userId, api, isMounted])

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <PageTitle title={t('Update merchant methods and fees')} />

            <Hidden xlUp={true}>
              {userId ? <MerchantMenu id={userId} /> : ''}
            </Hidden>
          </Stack>

          <MerchantAlias />

          <Grid container spacing={2}>
            <Grid item xs={12} xl={2}>
              <MerchantSubmenu current='methods-and-fees' id={userId} />
            </Grid>

            <Grid item xs={12} xl={10}>
              <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} open={true} />

              {loading && (<BackdropWrapper open={true} />)}

              {
                !loading && (<MethodsForm
                  userId={userId}
                  methods={filtered}
                  handleUpdateMethods={handleUpdateMethods}
                />)
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
