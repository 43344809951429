import { Formik, Form } from 'formik'
import { Grid, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import useIsMounted from 'hooks/useIsMounted'
import { useState } from 'react'
import SubmitWrapper from 'components/FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import Paper from '@mui/material/Paper'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import AddIcon from '@mui/icons-material/Add'

import GenericWrapper from 'components/FormUI/GenericWrapper/GenericWrapper'

//import * as Yup from 'yup'

import { objectFilter, objectMap } from 'utils/jsUtils'

import EditField from './EditFields'


export function GenericForm({
    onDone,
    url,
    metadata,
}) {
    const { t } = useTranslation()
    const api = useAxiosPrivate()
    const isMounted = useIsMounted()
    const [awaiting, setAwaiting] = useState(false)

    /* const FORM_VALIDATION = Yup.object().shape({
        address_type: Yup.string()
            .required(t('This field is required')),
        name: Yup.string()
            .required(t('This field is required')),
        country: Yup.string()
            .required(t('This field is required'))
            .max(2, t('Enter a valid country code')),
    }) */

    const fields = objectFilter(
        ({ value }) => !value.read_only,
        metadata.field_info
    )

    function onSubmit(values, actions) {
        api.post(url, values).then(({ data }) => {
            onDone(data.data)
            setAwaiting(false)
        })
            .catch((err) => {
                if (isMounted()) {
                    setAwaiting(false)
                    switch (err.response.status) {
                        case 400:
                            actions.setErrors(objectMap(({ value }) => t(value), err.response.data.error.errors))
                            break
                        default:
                            console.error(err)
                    }
                }
            })
        setAwaiting(true)
    }


    return <Formik
        initialValues={Object.fromEntries(Object.keys(fields).map((key) => [key, null]))}
        // validationSchema={FORM_VALIDATION}
        onSubmit={onSubmit}
    >
        <Form>
            <Paper>{awaiting ? <CircularProgress /> : <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'top',
                        }}
                    >
                        {
                            Object.entries(
                                fields
                            ).map(
                                ([key, value]) => {
                                    return <Box sx={{ padding: "0.4rem" }}> <GenericWrapper
                                        key={key}
                                        name={key}
                                        WrappedComponent={EditField}
                                        field={key}
                                        fieldMetadata={value}
                                    /></Box>
                                }
                            )
                        }
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <SubmitWrapper>{t('Save')}</SubmitWrapper>
                        <ButtonWrapper
                            variant="text"
                            onClick={() => onDone()}
                            startIcon={<CloseIcon />}>{t('Cancel')}
                        </ButtonWrapper>
                    </Stack>
                </Grid>
            </Grid>}</Paper>
        </Form>
    </Formik >

}


export default function CreateComponent({ url, metadata, newEntry = () => null }) {
    const { t } = useTranslation()

    const [creating, setCreating] = useState(false)

    function onDoneHandler(data) {
        if (data) {
            newEntry(data)
        }
        setCreating(false)
    }

    return (creating ?
        <GenericForm
            url={url}
            metadata={metadata}
            onDone={onDoneHandler}
        />
        :
        <ButtonWrapper
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setCreating(true)}
        >
            {t('New')}
        </ButtonWrapper>
    )
}
