import { Box, CircularProgress } from '@mui/material'

export default function AmountBox({
    title,
    amount,
    fractionDigits = 2,
    onClick = () => { },
    active = true,
    loading = false
}) {
    const activeOpacity = active ? 1 : 0.5
    const hoverOpacity = activeOpacity + (loading ? 0 : ((active ? -1 : 1) * 0.1))

    return (
        <Box
            sx={{
                bgcolor: '#0B1F3F',
                color: '#ffffff',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                opacity: activeOpacity,
                cursor: loading ? 'wait' : 'pointer',
                '&:hover': {
                    opacity: hoverOpacity
                }
            }}
            onClick={onClick}

        >
            <Box>{title}</Box>
            <Box sx={{
                fontSize: {
                    xs: 20,
                    sm: 30
                }, fontWeight: 'medium'
            }}>
                {loading && (<CircularProgress color="inherit" />)}
                {!loading && (new Intl.NumberFormat('es-CL', { maximumFractionDigits: fractionDigits }).format(amount))}
            </Box>
        </Box>
    )
}
