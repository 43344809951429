import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Typography, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { languages, currencies, ipn_versions, PM_PAYPAL, payouts_methods } from './../../../../enums/Merchants'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { ACCOUNT_CONFIG__UPDATE } from '../../../../enums/Caps'
import { BooleanNullableField } from 'components/Common/EditFields'
import GenericWrapper from 'components/FormUI/GenericWrapper/GenericWrapper'


export default function AccountConfigForm({
    initialFormState,
    FORM_VALIDATION,
    handleSubmit
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({
                values
            }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Payout information')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectWrapper
                            name="payout_method"
                            label={t('Payout method')}
                            options={payouts_methods}
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    {// eslint-disable-next-line eqeqeq
                        values.payout_method == PM_PAYPAL && (<Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="paypal_account"
                                label={t('Paypal account')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={!hasPermissions([
                                    ACCOUNT_CONFIG__UPDATE
                                ])}
                            />
                        </Grid>)}

                    <Box width="100%" mt={2} />

                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Merchant account config')}</Typography>
                    </Grid>

                    <Grid item xs={3} md={2}>
                        <SelectWrapper
                            name="settlement_currency"
                            label={t('Settlement currency')}
                            options={currencies}
                            disabled={true}
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={3} md={2}>
                        <SelectWrapper
                            name="language"
                            label={t('Language')}
                            options={languages}
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <GenericWrapper
                            name="countries"
                            WrappedComponent={BooleanNullableField}
                            field="countries"
                            fieldMetadata={{
                                "type": "string",
                                "required": false,
                                "read_only": false,
                                "label": "Countries where sales are allowed",
                                "max_length": 255,
                                "allow_null": true
                            }}
                            switchLabel={t("Allow sales to only some countries")}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name="settlement_fee"
                            label={t('Settlement fee')}
                            type="text"
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name="chargebacks_fee"
                            label={t('Chargeback fee')}
                            type="text"
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name="refunds_fee"
                            label={t('Refund fee')}
                            type="text"
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name="minimum_payout_amount"
                            label={t('Min. payout amount')}
                            type="text"
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name="waiting_time"
                            label={t('Waiting time')}
                            type="text"
                            disabled={!hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ])}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="ipn_version"
                            label={t('IPN version')}
                            options={ipn_versions}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={10}>
                        <TextfieldWrapper
                            name="webhook_url"
                            label={t('Notification URL')}
                            type="text"
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="old_uuid"
                            label={t('Old secret key')}
                            type="text"
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <GenericWrapper
                            name="psp_commerce_code"
                            WrappedComponent={BooleanNullableField}
                            field="psp_commerce_code"
                            fieldMetadata={{
                                "type": "string",
                                "required": false,
                                "read_only": false,
                                "label": "Psp commerce code",
                                "max_length": 45,
                                "allow_null": true
                            }}
                            switchLabel={t("Is a Psp")}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions([
                                ACCOUNT_CONFIG__UPDATE
                            ]) ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/merchants')}
                                startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
