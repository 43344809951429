import { Card, CardContent } from '@mui/material'
import { Stack } from '@mui/material'
import { Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableBackdrop from 'components/TableUI/TableBackdrop'
import Paper from '@mui/material/Paper'
import EditIcon from '@mui/icons-material/Edit'
import CircleIcon from '@mui/icons-material/Circle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton, Checkbox, ButtonGroup, Popover, Button } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'

import PageTitle from 'components/Misc/PageTitle'
import CreateComponent from './GenericCreateForm'
import EditField from './EditFields'

import useIsMounted from 'hooks/useIsMounted'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { tableHeadBuild } from "./Table"
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getIntersection, objectFilterByKey, objectMap } from 'utils/jsUtils'
import DatetimeValue from 'components/TableUI/DatetimeValue'

/*
export class GenericPermissions {
    constructor(view, create, update, destroy) {
        this.view = view
        this.create = create
        this.update = update
        this.destroy = destroy
    }

    static hasPermissions = useHasPermissions()

    static permissionsMapping = {
        "view": this.view,
        "create": this.create,
        "update": this.update,
        "destroy": this.destroy,
    }

    check(permission) {
        const got_permission = this.permissionsMapping[permission]
        if (got_permission === null) return false
        if (typeof got_permission == "boolean") return got_permission
        return this.hasPermissions(got_permission)
    }
}
*/


function EditCell({ editCallback, fieldMeta, currentValue, valid = (value) => true }) {
    const [newValue, setNewValue] = useState(currentValue)
    const [awaiting, setAwaiting] = useState(false)

    const isMounted = useIsMounted()
    const { t } = useTranslation()

    function onApply() {
        if (!valid(newValue)) return

        editCallback(newValue).catch((e) => {
            if (!isMounted()) return
            switch (e.response.status) {
                case 400:
                    toast.error(`${t("Invalid value for column")} '${t(fieldMeta.label)}'`)
                    break
                default:
                    toast.error(t("There was an unexpected error"))
            }
            setNewValue(currentValue)
            setAwaiting(false)
        })

        setAwaiting(true)
    }

    const FinishButtons = <ButtonGroup size="small">
        <Button onClick={() => editCallback()} variant="outlined">
            {t("Cancel")}
        </Button>
        <Button onClick={onApply} variant="contained" color="success">
            {t("Save")}
        </Button>
    </ButtonGroup>

    return <Stack direction="column" spacing={0.5} alignItems="center" width="fit-content" >
        <EditField
            field={fieldMeta.field}
            error={!valid(newValue)}
            fieldMetadata={fieldMeta}
            value={newValue}
            // sx={{ width: 90 }}
            size="small"
            onChange={(e) => setNewValue(e.target.value)}
        />
        {awaiting ? <CircularProgress /> : FinishButtons}
    </Stack>
}


function PopoverButton({ children, ButtonComponent, ...props }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return <>
        <ButtonComponent onClick={handleClick} />
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        >
            {children}
        </Popover>
    </>
}




function UrlView({ url }) {
    function ViewButton({ onClick }) {
        return <Button onClick={onClick}>
            <VisibilityIcon />
        </Button>
    }

    return <ButtonGroup variant="outlined" size="small">
        <Button onClick={() => window.open(url, "_blank")}>
            <OpenInNewIcon />
        </Button>
        <Button onClick={() => navigator.clipboard.writeText(url)}>
            <ContentCopyIcon />
        </Button>
        <PopoverButton ButtonComponent={ViewButton}>
            {url}
        </PopoverButton>
    </ButtonGroup>
}


function DataCell({ fieldMeta, value, editCallback = null }) {
    const [editing, setEditing] = useState(false)
    const [updatedValue, setUpdatedValue] = useState(value)

    const isMounted = useIsMounted()
    const { t } = useTranslation()

    const editButton = <IconButton style={{ opacity: 0.8, color: "darkslateblue" }} onClick={() => setEditing(true)}><EditIcon /></IconButton>
    const editButtonIf = editCallback === null ? "" : editButton

    const valueToShow = (() => {
        if (updatedValue === null) return <CircleIcon style={{ opacity: 0.1 }} />
        switch (fieldMeta.type) {
            case "choice": return fieldMeta.choices[updatedValue]
            case "boolean": return <Checkbox checked={updatedValue} style={{ color: "darkslateblue" }} disabled />
            case "url": return <UrlView url={updatedValue} />
            case "datetime": return <DatetimeValue date={updatedValue} />
            default: return updatedValue
        }
    })()

    const readDisplay = <Stack direction="row" alignItems="center">{editButtonIf}{valueToShow}</Stack>

    function editCallback_(newValue) {
        if (arguments.length === 0) {
            setEditing(false)
            return
        }
        const promise = editCallback(newValue)
            .then(() => {
                if (isMounted()) {
                    setEditing(false)
                    setUpdatedValue(newValue)
                    toast.success(`${t(fieldMeta.label)} ${t("updated succesfully")}`)
                }
            })
        return promise
    }

    return <TableCell>
        {editing ?
            <EditCell editCallback={editCallback_} fieldMeta={fieldMeta} currentValue={updatedValue} />
            : readDisplay
        }
    </TableCell>
}


export function MakeRowDataCellsComponent(metadata, editableFields = new Set(), editCallback = (id, data) => Promise.resolve(), hiddenFields) {
    const fields = Object.keys(metadata.field_info).filter((field) => !hiddenFields.has(field))

    function fieldMeta(field) {
        return { field: field, ...metadata.field_info[field] }
    }

    function makeCellEditCallback(id, field) {
        return function CellEditCallback(value) {
            return editCallback(id, { [field]: value })
        }
    }

    function makeCellEditCallbackArg(id, field) {
        return editableFields.has(field) ? makeCellEditCallback(id, field) : null
    }

    // TODO: Return primary key from server and use dynamically instead of always id
    return function RowDataCells({ entry }) {
        return fields.map((field, idx) => (
            <DataCell
                key={idx}
                fieldMeta={fieldMeta(field)}
                value={entry[field]}
                editCallback={makeCellEditCallbackArg(entry["id"], field)}
            />
        ))
    }
}


function DataRow({ data, actions, extraColFuncs, metadata, editableFields, editCallback, hiddenFields }) {
    const DataCells = MakeRowDataCellsComponent(metadata, editableFields, editCallback, hiddenFields)

    return <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
        <DataCells entry={data} />
        {extraColFuncs.map((func, idx) => <TableCell key={idx}>{func(data)}</TableCell>)}
        {/*<TableCell align="center">
            <TableActions actions={[
                {
                    id: `edit-${row.id}`,
                    handleClick: () => { handleNavigate(`${row.id}/edit`) },
                    icon: hasPermissions(ADDRESSES__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                    title: hasPermissions(ADDRESSES__UPDATE) ? t('Edit') : t('View')
                },
                {
                    id: `remove-${row.id}`,
                    handleClick: () => { return handleRemove(row.id) },
                    icon: <DeleteIcon />,
                    title: t('Remove'),
                    show: hasPermissions(ADDRESSES__DELETE)
                }
            ]} />
        </TableCell>*/}
    </TableRow>
}


export function ExtraCol(title, func) {
    return {
        "title": title,
        "func": (rowData, meta) => func(rowData, meta)
    }
}


export function GenericTable({ metadata, url, extraCols = [], editableFields = null, hiddenFields, createdRows = [], hideWriteOnly = true }) {
    const api = useAxiosPrivate()
    const { t } = useTranslation()
    const [data, setData] = useState(null)
    const isMounted = useIsMounted()

    const nonReadOnlyFields = () => new Set(Object.entries(metadata.field_info).filter((entry) => !entry[1].read_only).map((entry) => entry[0]))
    const editableFields_ = editableFields === null ? nonReadOnlyFields() : getIntersection(nonReadOnlyFields(), editableFields)

    const writeOnlyFields = () => new Set(Object.entries(metadata.field_info).filter((entry) => entry[1].write_only).map((entry) => entry[0]))
    const allHiddenFields = new Set([...hiddenFields, ...writeOnlyFields()])

    useEffect(() => {
        api.get(url).then((response) => {
            if (isMounted()) setData(response.data.data)
        })
    }, [api, url, isMounted])

    function editCallback(id, data) {
        return api.patch(`${url}${id}/`, data)
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    {metadata === null ?
                        <TableBackdrop open={true} />
                        : <>
                            {tableHeadBuild(
                                [...Object.values(objectFilterByKey((field) => !allHiddenFields.has(field), metadata.field_info)).map((field) => t(field.label)), ...extraCols.map((exCol) => exCol.title)]
                            )}
                            <TableBody>
                                {data ? [...createdRows, ...data].map((row, idx) => (
                                    <DataRow
                                        data={row}
                                        extraColFuncs={extraCols.map((exCol) => ((rowData) => exCol.func(rowData, metadata)))}
                                        key={idx}
                                        metadata={metadata}
                                        editableFields={editableFields_}
                                        hiddenFields={allHiddenFields}
                                        editCallback={editCallback}
                                    />
                                )) : <TableRow>
                                    {
                                        data === null ? <TableBackdrop open={data === null} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                    }
                                </TableRow>
                                }
                            </TableBody>
                        </>}
                </Table>
            </TableContainer>
        </>
    )
}


export function GenericCrud({ url, extraCols = [], editableFields = null, hiddenFields = new Set(), translatedChoiceFields, permissions, parentUrl }) {
    const api = useAxiosPrivate()
    const isMounted = useIsMounted()
    const [metadata, setMetadata] = useState(null)
    const [createdRows, setCreatedRows] = useState([])
    const { t } = useTranslation()

    const [translatedChoiceFieldsCalc] = useState(translatedChoiceFields ? translatedChoiceFields : new Set())

    useEffect(() => {
        api.options(url).then((response) => {
            function translateChoices(choices) {
                return objectMap(({ value }) => t(value), choices)
            }

            function translateChoicesInFieldInfo(fieldInfo) {
                return objectMap(({ key, value }) => {
                    if (translatedChoiceFieldsCalc.has(key)) {
                        return { ...value, choices: translateChoices(value.choices) }
                    }
                    return value
                }, fieldInfo)
            }
            const rawMetadata = response.data.data
            const fieldInfo = translateChoicesInFieldInfo(rawMetadata.field_info)
            if (isMounted()) setMetadata({ ...rawMetadata, field_info: fieldInfo })
        })
    }, [api, url, isMounted, t, translatedChoiceFieldsCalc])

    return (
        <>
            {metadata === null ? null :
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                        <CreateComponent
                            metadata={metadata}
                            url={url}
                            newEntry={(data) => setCreatedRows([data, ...createdRows])}
                        />
                    </Grid>
                    <Grid item xs={12} xl={12}>
                        <GenericTable
                            metadata={metadata}
                            url={url}
                            extraCols={extraCols}
                            editableFields={editableFields}
                            hiddenFields={hiddenFields}
                            createdRows={createdRows}
                        />

                    </Grid>
                </Grid>}
        </>
    )
}


export function GenericList({ url, title, extraCols = [], editableFields = null, hiddenFields = new Set(), translatedChoiceFields = new Set(), permissions, parentUrl, ...otherParams }) {
    return <Card {...otherParams}>
        <CardContent>
            {title ?
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <PageTitle title={title} />
                </Stack>
                : null
            }

            <GenericCrud
                url={url}
                extraCols={extraCols}
                editableFields={editableFields}
                hiddenFields={hiddenFields}
                permissions={permissions}
                parentUrl={parentUrl}
                translatedChoiceFields={translatedChoiceFields}
            />
        </CardContent>
    </Card>
}